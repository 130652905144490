@media only screen and (min-width: 770px) {
  #NavBar {
    background-color: rgb(244, 213, 211);
    height: 8%;
    width: 100%;
    top: 0;
    position: fixed;
    text-align: center;
    vertical-align: middle;
    z-index: 100;
    border-bottom: solid rgb(255, 236, 220) 2px;
  }

  .link-list {
    display: inline-block;
  }
  .hamburger {
    display: none;
  }
  .checkbox {
    display: none;
  }

  .current {
    color: black;
    border-bottom: 3px solid white;
  }
}

@media only screen and (max-width: 769px) {

  #NavBar {
    display: flex;
    background-color: black;
    max-height: 8%;
    width: 100%;
    top: 0;
    position: fixed;
    text-align: left;
    z-index: 100;
  }

  .current {
    border-bottom: 2px solid pink;
  }

  .container {
    max-width: 767px;
    width: 90%;
    margin: auto;
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 62px;
  }
  .navbar .menu-items {
    display: flex;
  }

  #NavBar .nav-container li {
    list-style: none;
  }

  #NavBar .nav-container a {
    text-decoration: none;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 0.7rem;
  }

  .nav-container a:hover {
    font-weight: bolder;
  }

  .nav-container {
    display: block;
    position: relative;
    height: 60px;
  }

  .nav-container .checkbox {
    position: absolute;
    display: block;
    height: 32px;
    width: 32px;
    top: 2vw;
    right: 0.5vh;
    z-index: 105;
    opacity: 0;
  }

  .nav-container .hamburger {
    display: block;
    height: 26px;
    width: 32px;
    position: absolute;
    top: 2vw;
    right: 0.5vh;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .nav-container .hamburger .bar {
    display: block;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background: white;
  }

  .nav-container .hamburger .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.4s ease-in-out;
  }

  .nav-container .hamburger .line2 {
    transition: transform 0.2s ease-in-out;
  }


  .nav-container .hamburger .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.4s ease-in-out;
  }

  #NavBar .menu-items {
    padding-top: 60px;
    position: fixed;
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.3);
    height: 100vh;
    width: 100%;
    transform: translate(250%);
    flex-direction: column;
    right: 0;
    padding-right: 20px;
    transition: transform 0.5s ease-in-out;
    text-align: center;
    z-index: 2;
    background-color: black;
  }


  #NavBar .menu-items li {
    margin-bottom: 1.2rem;
    font-size: 1.5rem;
    font-weight: 500;
  }

  #checkbox:checked ~ .menu-items {
    /* background-color: blue; */
    transform: translateX(0);
  }
  .nav-container input[type="checkbox"]:checked ~ .menu-items {
    background-color: red;
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger .line1 {
    transform: rotate(45deg);
  }


  .nav-container input[type="checkbox"]:checked ~ .hamburger .line2 {
    transform: scalex(50);
    background-color: pink;
  }

  .nav-container input[type="checkbox"]:checked ~ .hamburger .line3 {
    transform: rotate(-45deg);
  }
}


@media only screen and (min-width: 900px) {
  .projects-page-wrapper .project-wrapper {
    width: 70%;
    top: 15%;
    left: 20%;
    height: 80vh;
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    position: absolute;
    justify-content: center;
    font-size: 16px;
    /* gap: 0.5%; */
  }

  #proj-img {
    height: 100%;
    width: 100%;
    min-width: 300px;
  }

  .proj-img {
    margin: 0;
    width: 85%;
    height: 80.2%;
    border-radius: 5%;
    position: relative;
  }

  .proj-img1 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 1;
    position: relative;
  }

  .proj-img1:hover .proj1-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 30px;
  }

  .proj1-des1 {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    font-size: 20px;
    text-align: center;
  }
  .proj1-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 5%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    width: auto;
    margin: auto;
    text-align: center;
  }

  .proj-img1:hover .proj1-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
  }

  .proj-img2 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row: 1;
    position: relative;
  }

  .proj-img2:hover .proj2-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
    
  }

  .proj2-des1 {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    font-size: 30px;
    text-align: center;
  }
  .proj2-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 5%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    font-size: 20px;
    margin: auto;
    text-align: center;
    font-size: 16px;
  }

  .proj-img2:hover .proj2-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: 600;
  }

  .proj-img3 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row: 2;
    position: relative;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
  }

  .proj3-img3 #proj-img {
    opacity: 1;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
  }

  .proj-img3:hover {
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    background-color: transparent;
  }

  .proj-img3:hover .proj3-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
    font-size: 30px;
  }

  .proj3-des1 {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    text-align: center;
  }
  .proj3-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 5%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    margin: auto;
    text-align: center;
  }

  .proj-img3:hover .proj3-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: 600;
  }

  .proj-img4 {
    grid-column-start: 3;
    grid-column-end: 5;
    grid-row: 2;
    position: relative;
  }

  .proj-img #proj-img {
    border: solid rgb(255, 236, 220) 5px;
    border-radius: 5%;
  }

  .proj-img4:hover .proj4-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 30px;
  }

  .proj4-des1 {
    position: absolute;
    top: 0;
    bottom: 60%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    text-align: center;
  }
  .proj4-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    width: auto;
    margin: auto;
    text-align: center;
  }

  .proj-img4:hover .proj4-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 800px) and (max-width: 970px) {
  h4 {
    display: none;
  }

  
}

@media only screen and (max-width: 899px) {
  .projects-page-wrapper .project-wrapper {
    top: 10vh;
    width: 95vw;
    left: 1.5vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
  }
  #heading {
    display: none;
  }
  .proj-img #proj-img {
    border: solid rgb(255, 236, 220) 5px;
    border-radius: 5%;
  }
  #proj-img {
    height: 100%;
    width: 100%;
    min-width: 250px;
  }
  #proj-img:hover {
    box-shadow: inset 0 0 2000px rgba(255, 255, 255, 0.5);
  }

  .proj-img {
    /* margin-top: 2%; */
    width: 100%;
    border-radius: 5%;
    position: relative;
  }

  .proj-img1 {
    position: relative;
  }

  .proj-img1:hover .proj1-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 30px;
  }

  .proj1-des1 {
    position: absolute;
    top: 0;
    bottom: 60%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    text-align: center;
  }
  .proj1-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    width: auto;
    margin: auto;
    text-align: center;
  }

  .proj-img1:hover .proj1-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
  }

  .proj-img2 {
    position: relative;
  }

  .proj-img2:hover .proj2-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
  }

  .proj2-des1 {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    font-size: 30px;
    text-align: center;
  }
  .proj2-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    font-size: 20px;
    font-weight: 600;
    margin: auto;
    text-align: center;
  }

  .proj-img2:hover .proj2-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
  }

  .proj-img3 {
    position: relative;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
  }

  .proj3-img3 #proj-img {
    opacity: 1;
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
  }

  .proj-img3:hover {
    -moz-transition: all 1s linear;
    -ms-transition: all 1s linear;
    -o-transition: all 1s linear;
    -webkit-transition: all 1s linear;
    transition: all 1s linear;
    background-color: transparent;
  }

  .proj-img3:hover .proj3-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    color: black;
    font-size: 30px;
  }

  .proj3-des1 {
    position: absolute;
    top: 0;
    bottom: 50%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    text-align: center;
  }
  .proj3-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    margin: auto;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
  }

  .proj-img3:hover .proj3-des2 {
    visibility: visible;
    opacity: 1;
    font-size: 16px;
    background-color: transparent;
    color: black;
    font-size: 20px;
    font-weight: 600;
  }

  /* .proj-img1 {
    position: relative;
  } */

  .proj-img4:hover .proj4-des1 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 30px;
  }

  .proj4-des1 {
    position: absolute;
    top: 0;
    bottom: 60%;
    left: 3%;
    right: 0;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    text-align: center;
  }
  .proj4-des2 {
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: 15%;
    background-color: transparent;
    color: #fff;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s, visibility 0.2s;
    border-radius: 5%;
    width: auto;
    margin: auto;
    text-align: center;
  }

  .proj-img4:hover .proj4-des2 {
    visibility: visible;
    opacity: 1;
    background-color: transparent;
    font-size: 20px;
    font-weight: 600;
  }

}

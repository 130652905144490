@media only screen and (min-width: 770px) {
  .AboutMe-page .about-me-wrapper {
    height: 55%;
    width: 50%;
    top: 10%;
    margin-left: 20%;
    margin-bottom: 5%;
    gap: 5%;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-flow: row wrap;
    font-size: 16px;
    position: absolute;
  }

  .AboutMe-page .about-me {
    background-color: rgb(255, 255, 255);
    padding-left: 1.5%;
    padding-right: 1.5%;
    padding-bottom: 1%;
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem white); */

  }
  .AboutMe-page .secret {
    line-height: 1;
    font-size: 4.2vh;
    float: left;
    font-weight: bold;
  }
  .AboutMe-page .name {
    font-size: 3vh;
    font-weight: 550;
  }

  .AboutMe-page #pic1-words {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    padding: 2%;
  }

  .AboutMe-page #pic2-words {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */

    padding: 2%;
  }
  .AboutMe-page #quote-words {
    padding: 2%;
    border-radius: 10px;
    background-color: white;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }
  #quote-words h4 {
    justify-self: center;
  }
  .AboutMe-page .hobbies {
    padding-left: 4%;
    width: 120%;
    background-color: white;
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */

  }
  .AboutMe-page .favorite-books-movies {
    padding-left: 4%;
    width: 120%;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }

  .AboutMe-page h4 {
    padding-left: 2%;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
  }

  ul {
    list-style: none;
    display: inline-block;
    width: 90%;
    height: 100%;
    position: absolute;
    right: 5%;
  }
  ul li {
    display: inline;
    padding-left: 3%;
    font-size: 18px;
    font-weight: 700;
    margin-right: 1%;
  }
  ul li a {
    color: black;
    -webkit-transition: color 1s;
    -moz-transition: color 1s;
    -ms-transition: color 1s;
    -o-transition: color 1s;
    transition: color 1s;
  }

  ul li a:hover {
    color: black;
  }

  #footer {
    display: none;
  }

  #pic1-img {
    position: fixed;
    right: 5%;
    top: 33%;
    width: 17%;
    min-width: 180px;
  }

  #pic1-img img {
    width: 100%;
    border: solid rgb(255, 236, 220) 5px;
    /* filter: drop-shadow(0 1rem 1rem rgb(196, 80, 157)); */
    border-radius: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  }
  #pic2-img {
    display: none;
  }
}

@media only screen and (max-width: 769px) {
  .AboutMe-page .about-me-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .AboutMe-page .about-me {
    background-color: white;
    font-size: 16px;
    width: 90%;
    margin: 4%;
    padding: 1.5%;
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    line-height: 25px;
  }
  .AboutMe-page .secret {
    line-height: 0.5;
    font-size: 4vh;
    float: left;
    font-weight: bold;
  }
  #pic1-img {
    display: none;

  }
  #pic2-img {

    margin-left: 25%;

    margin-top: 15%;
    width: 37%;
    min-width: 180px;
  }

  #pic2-img img {
    width: 130%;
    border: solid rgb(255, 236, 220) 5px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    border-radius: 50%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    margin: auto;
  }

  .AboutMe-page #quote-words {
    background-color: white;
    font-size: 16px;
    width: 90%;
    margin: 3%;
    padding: 2%;
    border-radius: 10px;
    line-height: 25px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }
  .AboutMe-page .hobbies {
    background-color: white;
    font-size: 16px;
    width: 90%;
    margin: 4%;
    padding: 1.5%;
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }
  .AboutMe-page .favorite-books-movies {
    background-color: white;
    font-size: 16px;
    width: 90%;
    margin-top: 4%;
    margin-left: 3.5%;
    padding: 1.5%;
    border-radius: 10px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }
  .AboutMe-page h5 {
    padding-left: 42%;
    font-weight: 600;
    font-size: 2vh;
  }

  .AboutMe-page h6 {
    padding-left: 30%;
    font-weight: 600;
    font-size: 2vh;
  }
}

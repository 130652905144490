@media only screen and (min-width: 601px) {
  .Resume-page .Resume-page-wrapper {
    height: 68%;
    width: 57%;
    top: 10%;
    left: 20%;
    bottom: 5%;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    overflow: auto;
    padding: 2%;
    border-radius: 10px;
    border: solid rgb(255, 236, 220) 3px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    position: absolute;

  }

  .Resume-page-wrapper ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: static;
    height: auto;
    padding-inline-start: 1px;
    margin-bottom: 2%;
    list-style: none;
    width: 100%;
  }

  #work-xp {
    width: 100%;
  }
  #work-xp h2 {
    text-align: center;
    border-top: black solid 2px;
  }
  #tech-skills {
    justify-content: center;
  }
  #work-xp ul li::before {
    content: "\2022";
    color: teal;
    font-weight: 600;
    display: inline-block;
    width: 1em;
  }
  .Resume-page-wrapper #job-title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-right: 1%;
    color: teal;
  }

  .Resume-page-wrapper #ed-title {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    padding-right: 1%;
    text-align: center;
  }
  #ed-title h3 {
    margin-right: 2%;
  }
  .Resume-page-wrapper li {
    padding-left: 0;
    margin-left: 1%;
    font-weight: 400;
  }

  .Resume-page-wrapper h1 {
    margin: 0;
    width: 100%;
    font-size: 40px;
  }

  .Resume-page-wrapper h2 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    margin-left: 0;
    color: teal;
  }
  .Resume-page-wrapper h3 {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
  }
  .Resume-page-wrapper h4 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    margin-left: 0;
    font: bold;
  }


  .Resume-page-wrapper p {
    margin-bottom: 0;
    margin-top: 1%;
    padding: 0;
    font-size: 1.125rem;
  }
  .Resume-page-wrapper a {
    text-align: center;
    color: black;
  }

  #download-link {
    height: 10%;
    width: 100%;
    top: 86%;
    position: fixed;
    text-align: center;
    vertical-align: middle;
    z-index: 100;
  }

  #download-link button {
    color: black;
    width: 10%;
    margin-bottom: 0;
    padding: 1%;
    background-color: white;
    font-size: medium;
    border: solid rgb(255, 236, 220) 3px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
  }

  #download-link a:hover {
    color: rgb(244, 213, 211);
  }


  .Resume-page button {
    width: 75%;
    padding: 4.5%;
    background-color: white;
    font-size: medium;
    font-weight: 200;
    border-radius: 10px;
    margin: 2%;
    margin-left: 45%;
    border: solid rgb(255, 236, 220) 3px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media only screen and (max-width: 600px) {
  .Resume-page .Resume-page-wrapper {
    height: 68%;
    width: 80%;
    top: 10%;
    left: 7%;
    bottom: 5%;
    font-family: "Poppins", sans-serif;
    display: flex;

    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    overflow: auto;
    padding: 2%;
    border-radius: 10px;
    border: solid rgb(255, 236, 220) 3px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */
    position: absolute;

  }
  .Resume-page-wrapper h2 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    left: 0;
    color: teal;
  }
  .Resume-page-wrapper h3 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    left: 0;
  }
  .Resume-page-wrapper h4 {
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
    margin-left: 0;
    font: bold;
    width: 20%;
  }

  #download-link a:hover {
    color: rgb(244, 213, 211);
  }
  .Resume-page button {
    position: absolute;
    padding: 2.5%;
    background-color: white;
    font-size: 16px;
    font-weight: 200;
    border-radius: 10px;
    border: solid rgb(255, 236, 220) 3px;
    /* filter: drop-shadow(0 1rem 1rem rgb(203, 160, 154)); */

    width: 25%;
    bottom: 5%;
    left: 35%;
  }
}


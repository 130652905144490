@media only screen and (min-width: 770px) {
  body {
    background-color: black;
  }
  .current {
    color: rgb(244, 213, 211);
    border-bottom: 2px solid white;
  }

  .fa-2x {
    display: none;
  }

  a {
    text-decoration: none;
    color: black;
  }

  #heading {
    top: 22%;
    left: 0;
    margin: 0;
    font-size: 15vh;
    writing-mode: vertical-rl;
    -webkit-text-stroke: 1px white;
    position: fixed;
    color: rgb(244, 213, 211);

  }
}

@media only screen and (max-width: 769px) {
  body {
    background-color: rgb(244, 213, 211);
  }

  .fa-3x:hover {
    color: rgb(244, 213, 211);
  }

  #heading {
    display: none;
  }

  h4 {
    font-size: 25px;
    text-align: center;
  }

  textarea {
    font-size: 20px;
    height: 50%;
  }

  a {
    text-decoration: none;
    color: black;
  }

  .fa-2x {
    padding: 10px;
  }
}
